import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import "./index.css";
import './assets/Khand/stylesheet.css'
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import WatIsAstrologie from "./pages/Astrologie/WatIsAstrologie";
import Voorstellen from "./pages/Voorstellen/Voorstellen";
import PrijzenEnContact from "./pages/Contact/PrijzenEnContact";
import ScrollToTop from './components/ScrollToTop'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/astrologie" element={<WatIsAstrologie />}/>
                <Route path="/voorstellen" element={<Voorstellen/>}/>
                <Route path="/prijzenencontact" element={<PrijzenEnContact/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
