/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import {
    Grid,
    Header,
    Segment,
} from 'semantic-ui-react'
import Footer from '../Common/Footer'
import ResponsiveContainer from "../Common/ResponsiveContainer";


const HomePage = () => (
    <ResponsiveContainer>
        <Segment style={{padding: '8em 0em', backgroundColor: '#FFFFF'}} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{fontSize: '3em'}}>
                            Astro-Seven
                        </Header>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            In mijn praktijk Astro-Seven help ik mensen zicht te krijgen op wie ze zijn. Een horoscoop
                            is een soort plattegrond waarin een astroloog het potentieel van de client kan zien. Een
                            horoscoop kan inzicht geven in je functioneren, talenten en eventueel problemen. Hierdoor
                            krijg je het vermogen je leven bewuster vorm te geven en daarin beter tot je recht te komen.
                            Meer inzicht is vaak al voldoende om een volgende stap te zetten. Naast de horoscoopduiding
                            maak ik gebruik van intuitieve technieken om nog meer relevante informatie te verkrijgen.
                            Mijn praktijk heet Astro-Seven, omdat 7 een geluksgetal is en staat voor transformatie. Ik
                            wil jou graag helpen in je eigen transformatieproces. Een transformatie die je uiteindelijk
                            geluk kan brengen.
                        </p>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Voor het maken van een horoscoop heb ik je geboortedatum, geboortetijdstip en geboorteplaats
                            nodig. Als je je geboortetijdstip niet exact weet kun je die meestal opvragen bij de
                            burgerlijke stand van de gemeente waar je geboren bent. Verder zou ik ook graag een foto
                            ontvangen, waar jij alleen op staat. Deze foto hoeft niet recent te zijn en mag ook een
                            pasfoto zijn. Deze foto krijg je bij het consult weer terug.
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{paddingTop: '1.5em', fontSize: '3em'}}>
                            Wat kunt u van een consult verwachten?
                        </Header>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Een consult duurt ongeveer twee en een halfuur. In dit consult nemen we samen je horoscoop
                            door en staan we stil bij de specifieke vragen die je hebt. Veel van wat ik vertel krijg je
                            ook mee op schrift, zodat je thuis alles nog eens rustig na kunt lezen. Het is mogelijk nog
                            een vervolgconsult aan te vragen waarin we op een aantal thema's dieper in kunnen gaan
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Footer/>
    </ResponsiveContainer>
)

export default HomePage
