/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../Common/Footer'
import ResponsiveContainer from "../Common/ResponsiveContainer";
import {Grid, Header, Segment, Image} from "semantic-ui-react";

const Voorstellen = () => (
    <ResponsiveContainer>

        <Segment style={{padding: '8em 0em', backgroundColor: '#FFFFF'}} vertical>
            <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{fontSize: '3em'}}>
                            Even voorstellen
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>
                            Ik ben Katja van Egmond-Weststeyn, geboren in Alphen aan den Rijn op 1 februari 1965. Ik ben
                            getrouwd en heb 3 kinderen. Ik woon sinds 1996 in de Vijfhoek in Deventer. Ik ben gek op
                            dansen, zingen, tennissen, lezen en natuurlijk astrologie. Mijn fascinatie voor astrologie
                            is al jaren geleden begonnen, ik ben van jongs af aan al op zoek naar wat mensen beweegt en
                            wat hen weerhoudt om helemaal zichzelf te zijn.
                        </p>

                        <Header as='h3' style={{fontSize: '1.6em'}}>
                            Werkervaring en opleidingen
                        </Header>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>

                            Na de middelbare school heb ik gestudeerd aan de
                            pedagogische-academie in Oegstgeest. Na mijn studie heb ik 1 jaar als lerares gewerkt op
                            een
                            I.S.K. ( internationale schakelklas) in Leiden en vervolgens 2 jaar op een basisschool
                            in
                            Alphen aan den Rijn.
                            Na de geboorte van mijn eerste kind ben ik gestopt met werken.
                            Vervolgens heb ik 3 jaar gestudeerd aan de opleiding Psychologische Astrologie in
                            Apeldoorn,
                            wat bestond uit: persoonlijke horoscoopduiding, relatie astrologie, beroepskeuze
                            astrologie,
                            medische astrologie, voorspellende astrologie, kinderhoroscopen. Daarna heb ik in 2004
                            het
                            eerste jaar van de opleiding tot Psycho-energetisch therapeut gevolgd bij de SIO te
                            Zeddam
                            en een certificaat intuïtieve ontwikkeling ontvangen. Sinds 2008 ben ik werkzaam als
                            astrologe. De afgelopen jaren heb ik veel ervaring opgedaan in het werken met cliënten.
                        </p>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}>
                            <Image src='img/IMG_katja2020.jpg' style={{maxHeight: '500px'}}/>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Footer/>
    </ResponsiveContainer>
)

export default Voorstellen
