/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../Common/Footer'
import ResponsiveContainer from "../Common/ResponsiveContainer";
import {Grid, Header, Segment} from "semantic-ui-react";

const WatIsAstrologie = () => (
    <ResponsiveContainer>

        <Segment style={{padding: '8em 0em'}} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{fontSize: '3em'}}>
                            Wat is astrologie
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Astrologie is een leer, een manier van denken die uitgaat van het idee dat alles in het
                            universum samenhangt en verband houdt met elkaar. Met andere woorden: de gebeurtenissen op
                            aarde hebben te maken met omstandigheden buiten de aarde. Die omstandigheden buiten de aarde
                            beïnvloeden niet alleen eb en vloed, maar in feite alles wat op aarde leeft, dus ook de
                            mens. Astrologie is ook een eeuwenoude ervaringswetenschap die al bestaat vanaf 2000 jaar
                            voor Christus. Astronomie en astrologie gingen aanvankelijk hand in hand tot de 17e eeuw.
                            Daarna werd astrologie vooral door de 'kerken' verboden. In deze tijd staat het echter
                            steeds meer in de belangstelling. Helaas overheerst in de media een verkeerde voorstelling
                            van zaken over astrologie en daardoor worden de serieuze astrologen geconfronteerd met veel
                            misverstanden en verkeerde verwachtingen. Alsof alles al bij voorbaat vast zou liggen en dat
                            astrologie alleen maar concreet voorspellend zou zijn, of dat het een geloof is. De
                            horoscoop in de dag- en weekbladen heeft niets te maken met de echte astrologie. Het maken
                            van een goede horoscoop vereist een serieuze en diepgaande studie van de astrologie. Het is
                            een techniek die werkt en die gebaseerd is op eeuwenlange ervaring in de praktijk. We kunnen
                            niet verklaren hoe de astrologie werkt, maar we kunnen wel degelijk onderzoeken of ze werkt.
                            De astrologie verschaft unieke kwalitatieve informatie, die zich moeilijk leent voor
                            kwantitatief onderzoek, zoals statistiek.

                        </p>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Astrologie is de studie van de geaardheid van de mens, een instrument om jezelf beter te
                            leren kennen en anderen beter te begrijpen, maar dat mag uiteraard niet ten koste gaan van
                            je eigen verantwoordelijkheid. Door de stand van de hemellichamen en de verbanden die ze met
                            elkaar hebben op een bepaald tijdstip in kaart te brengen krijgen we een horoscoop. Zo'n
                            horoscoop levert gegevens die de astroloog moet kunnen vertalen in een begrijpelijk verhaal.
                            De horoscoop maakt het bijvoorbeeld mogelijk nog latent aanwezige mogelijkheden bij iemand
                            te tonen, maar geeft ook een beeld van bepaalde neigingen die een belemmering kunnen vormen
                            voor je (geestelijke) groei. Als je dit weet kun je proberen dit in een meer gewenste
                            richting te leiden.

                        </p>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            Het doel van een horoscoopduiding is het verkrijgen van meer zelfinzicht. Dat kan helpen te
                            voorkomen dat je in je ontwikkeling van jezelf vervreemd en daardoor geen vat op je
                            identiteit kunt krijgen. Bovendien kan het een ondersteuning zijn om tot meer zelfinzicht te
                            komen. Je karakter wordt gevormd door aanleg, erfelijkheid, opvoeding en omgeving. Een
                            geboortehoroscoop geeft een beeld van je aanleg, dat wat diep in jezelf zit, je (verborgen)
                            behoeften en talenten.

                        </p>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            De horoscoop is individueel en de nauwkeurigheid is afhankelijk van de kennis van
                            het juiste
                            tijdstip van de geboorte. De horoscoop wordt berekend aan de hand van
                            geboorteplaats,
                            geboortedatum en geboortetijd. Zonder een juiste geboortetijd is het niet mogelijk
                            om een
                            horoscoop te maken.

                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{paddingTop: '1.5em', fontSize: '3em'}}>
                            Wat is psychologische astrologie?
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p style={{fontSize: '1.33em', textAlign: 'justify', textJustify: 'inter-word'}}>
                            De Westerse astrologie waar ik mee werk is gebaseerd op de psychologie van Carl G. Jung,
                            en
                            kent verschillende vormen. De bekendste is de karakterduiding, oftewel psychologische
                            duiding. Jung deed zelf ook veel aan astrologie. Hij noemde het psychologie van de
                            oudheid.
                            Jung gaat ervan uit dat een kind niet als een onbeschreven blad wordt geboren. Zijn
                            individuele aanleg is aangeboren. De horoscoop ziet eruit als een mandala(cirkelvormige
                            tekening) en is een symbool voor heelheid. De horoscoop geeft je aangeboren potentieel
                            aan
                            en Jung streefde ernaar om je aangeboren potentieel te verwerkelijken. Dit heet het
                            individuatieproces. De andere vormen waar ik mee werk zijn: relatie astrologie,
                            beroepskeuze
                            astrologie, medische astrologie, voorspellende astrologie en karmische astrologie.
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Footer/>
    </ResponsiveContainer>
)

export default WatIsAstrologie
