import {useState, useRef} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import {Points, PointMaterial} from '@react-three/drei'
import * as random from 'maath/random/dist/maath-random.esm'

export default function Footer() {
    return (
        <div style={{background: 'linear-gradient(150deg, #0E1935, #0A0F25)', position: 'relative', height: '500px'}}>
            <Canvas
                camera={{position: [0, 0, 1]}}
                style={{ height: '100%'}}>
                <Stars/>
            </Canvas>
            <Overlay/>
        </div>
    )
}

function Overlay() {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', // this will center your text horizontally and vertically
            color: 'white' // the color of the text
        }}>
            <h1 style={{maxWidth: '75%', fontFamily: 'khand'}}>Je hoeft niet iemand te worden, want je bent al iemand. Geef dat de vrijheid
                en ervaar wat je bent.
            </h1><p></p>
            <p style={{fontFamily: 'khand'}}>
                Anandajay
            </p>
        </div>
    )
}

function Stars(props) {
    const ref = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(5000), {radius: 1.5}))
    useFrame((state, delta) => {
        ref.current.rotation.x -= delta / 10
        ref.current.rotation.y -= delta / 15
    })
    return (
        <group rotation={[0, 0, Math.PI / 4]}>
            <Points ref={ref} positions={sphere} stride={3} frustumCulled={false} {...props}>
                <PointMaterial transparent color="#ffa0e0" size={0.005} sizeAttenuation={true} depthWrite={false}/>
            </Points>
        </group>
    )
}
