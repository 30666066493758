/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../Common/Footer'
import ResponsiveContainer from "../Common/ResponsiveContainer";
import {Grid, Header, Segment} from "semantic-ui-react";
import '../Pages.module.css'

const PrijzenEnContact = () => (
    <ResponsiveContainer>
        <Segment style={{padding: '8em 0em'}} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h3' style={{fontSize: '3em'}}>
                            Astro-Seven
                        </Header>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>
                            Het tarief voor een geboortehoroscoop, inclusief consult, is € 85.
                        </p>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>
                            Naast het maken van een geboortehoroscoop kan ik de volgende disciplines voor u
                            verzorgen: - relatie astrologie; - beroepskeuze astrologie; - medische astrologie; -
                            voorspellende astrologie; - karmische astrologie. Het tarief per discipline is € 45.
                        </p>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>
                            Het tarief voor eventuele vervolgconsulten is € 50 per consult.
                        </p>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>
                            Voor clienten met een minimuminkomen gelden gereduceerde tarieven.
                        </p>
                        <p style={{fontSize: '1.33em', textJustify: 'inter-word', textAlign: 'justify'}}>
                            Je kunt per email of telefoon contact met mij
                            opnemen: <a
                                href='mailto:katjavanegmond@gmail.com'
                                style={{color: 'black', textDecoration: 'underline'}}
                            >katjavanegmond@gmail.com</a> of
                            06-23719295
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
        <Footer/>
    </ResponsiveContainer>
)

export default PrijzenEnContact
