import './App.css';
import HomePage from "./pages/Home/HomePage";

function App() {
  return (
      <HomePage/>
  );
}

export default App;
