import React, {Component} from 'react'
import {createMedia} from '@artsy/fresnel'
import PropTypes from 'prop-types'
import 'semantic-ui-css/semantic.min.css';
import {
    Icon,
    Header,
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react'
import {Link, NavLink} from 'react-router-dom';

const {Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

class MobileContainer extends Component {
    state = {}

    handleSidebarHide = () => this.setState({sidebarOpened: false})

    handleToggle = () => this.setState({sidebarOpened: true})

    render() {
        const {children, location} = this.props
        const {sidebarOpened} = this.state

        const isActive = (path) => location.pathname === path;
        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                        style={{backgroundColor: 'rgba(255, 255, 255, 0.91)'}}
                    >

                        <Menu.Item as={Link} to='/' active={isActive('/')}>
                            Astro-Seven
                        </Menu.Item>
                        <Menu.Item as={NavLink} to='/astrologie' active={isActive('/astrologie')}>
                            Wat is astrologie
                        </Menu.Item>
                        <Menu.Item as={NavLink} to='/voorstellen' active={isActive('/voorstellen')}>
                            Voorstellen
                        </Menu.Item>
                        <Menu.Item as={NavLink} to='/prijzenencontact' active={isActive('/prijzenencontact')}>
                            Prijzen en contact
                        </Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            textAlign='center'
                            style={{
                                backgroundImage: 'url(img/header.jpg)',
                                backgroundPosition: 'right',
                                backgroundSize: 'cover',
                                minHeight: '30vh',
                                padding: '0em 0em'}}
                            vertical
                            verticalAlign='middle'
                        >
                            <Menu secondary inverted borderless fixed='top' size='large'
                                style={{ backgroundColor: 'rgba(20, 35, 60, 0.6)'}}>
                                <Menu.Item onClick={this.handleToggle}>
                                    <Icon name='sidebar'/>
                                </Menu.Item>
                                <Menu.Item as={Link} to='/'>
                                    <Header as='h2' style={{color: 'white'}}>Astro Seven</Header>
                                </Menu.Item>
                            </Menu>
                        </Segment>
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Media>
        )
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node,
}


export default MobileContainer
