import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createMedia} from '@artsy/fresnel'
import {Segment, Menu, Container } from 'semantic-ui-react';
import {Link, NavLink} from 'react-router-dom';
import {InView} from 'react-intersection-observer'; // Ensure you have this for the InView component

const {Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

class DesktopContainer extends Component {
    state = {}

    toggleFixedMenu = (inView) => this.setState({fixed: !inView})

    render() {
        const {children, location} = this.props;
        const {fixed} = this.state;

        // Determine if the current menu item is active based on the location
        const isActive = (path) => location.pathname === path;

        return (
            <Media greaterThan='mobile'>
                <InView onChange={this.toggleFixedMenu}>
                    <Segment textAlign='center'
                             style={{
                                 backgroundSize: 'cover',
                                 minHeight: '500px',
                                 backgroundImage: 'url(img/header.jpg)',
                                 backgroundPosition: 'right',
                                 padding: '0em' }}
                             vertical>
                        <Menu
                            fixed={fixed ? 'top' : 'top'}
                            borderless
                            secondary
                            pointing
                            style={{ backgroundColor: fixed? '#fff' : 'rgba(20, 35, 60, 0.6)'}}
                            inverted={ !fixed }
                            size='massive'
                        >
                            <Container>
                                <Menu.Item as={Link} to='/' active={isActive('/')}>
                                    Astro-Seven
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/astrologie' active={isActive('/astrologie')}>
                                    Wat is astrologie
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/voorstellen' active={isActive('/voorstellen')}>
                                    Voorstellen
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/prijzenencontact' active={isActive('/prijzenencontact')}>
                                    Prijzen en contact
                                </Menu.Item>
                            </Container>
                        </Menu>
                    </Segment>
                </InView>
                {children}
            </Media>
        );
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object.isRequired, // Ensure location is marked as required
}

// Note: Now, you should use DesktopContainerWithRouter instead of DesktopContainer directly in your app
export default DesktopContainer
